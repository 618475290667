import { Injectable } from '@angular/core';
import { InfoService } from '@generated/swagger/isa-api';

@Injectable()
export class DomainDashboardService {
  constructor(private readonly _infoService: InfoService) {}

  feed() {
    return this._infoService.InfoInfo({});
  }
}
